import React, { useState } from 'react';
import CountryList from '../country/countrycodes-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'; // Import icons

const UatisChecker = () => {
  const [dialCode, setDialCode] = useState('');
  const [numbers, setNumbers] = useState('');
  const [exists, setExists] = useState(null);
  const [error, setError] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [isBusiness, setIsBusiness] = useState(null);
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [site, setSite] = useState('');


  const handleCountry = (selectedDialCode) => {
    setDialCode(selectedDialCode);
  }

  const handleCheck = () => {
    const fullNumber = `${dialCode}${numbers}`;
    const options = {
      method: 'POST',
      headers: {
        apikey: process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "number": fullNumber })
    };

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/fetchProfile/DSWHATS`, options)
      .then(response => response.json())
      .then(data => {
        if (data && data.numberExists !== undefined) {
          setExists(data.numberExists);
          setProfilePictureUrl(data.picture || '');
          setIsBusiness(data.isBusiness || false);
          setEmail(data.email || '');
          setDescription(data.description || '');
          setSite(data.website || '');
        } else {
          setExists(false);
          setProfilePictureUrl(null);
          setIsBusiness(false);
          setEmail(null);
          setDescription(null);
          setSite(null);
        }
      })
      .catch(err => setError(err.message));
  };

  return (
    <div className='container'>
      <div className='header'>
        <h1>Uatis?</h1>
        <h2>Check if a WhatsApp phone number exists</h2>
        <p>Note: CountryCode+PhoneNumber</p>
      </div>
      <CountryList onCountryChange={handleCountry} />
      <div>
        <input
          className='input'
          type="number"
          value={numbers}
          onChange={(e) => setNumbers(e.target.value)}
          placeholder="Phone number..."
        />
      </div>
      <div>
        <button
          className='button'
          onClick={handleCheck}
        >
          Check
        </button>
      </div>
      <div className='result'>
        {exists !== null && (
          <div>
<table>
  <tbody>
    <tr>
      <td>Uatis:</td>
      <td>
        {exists ? (
          <FontAwesomeIcon icon={faCircleCheck} size='2xl' style={{ color: "#00FFB3" }} />
        ) : (
          <FontAwesomeIcon icon={faCircleXmark} size='2xl' style={{ color: "#FF0000" }} />
        )}
      </td>
    </tr>
    <tr>
      <td>Phone Number:</td>
      <td>{numbers}</td>
    </tr>
    <tr>
      <td>Image Profile:</td>
      <td>
        {profilePictureUrl ? <img src={profilePictureUrl} alt="Profile" style={{ width: '50px', borderRadius: '20%' }} />
         : 'Private profile'}
      </td>
    </tr>
    <tr>
      <td>Send message:</td>
      <td>
        {exists ? <a href={`https://wa.me/${dialCode}${numbers}`}  rel="noreferrer" target="_blank">Chat on WhatsApp</a>
         : 'N/A'}
      </td>
    </tr>
    <tr>
      <td>Email:</td>
      <td>{email ? email : 'N/A'}</td>
    </tr>
    <tr>
      <td>Business:</td>
      <td>{isBusiness ? 'Yes' : 'No'}</td>
    </tr>
    <tr>
      <td>Description:</td>
      <td>{description ? description : 'N/A' }</td>
    </tr>
    <tr>
      <td>Site:</td>
      <td>{site ? <a href={`{site}`} rel="noreferrer" target="_blank">{site}</a> : 'N/A'}</td>
    </tr>
  </tbody>
</table>

          </div>
        )}
        {error && (
          <div>
            <h2>Erro:</h2>
            <pre>{error}</pre>
          </div>
        )}
        <h6>Developed by <a href='https://danielsoares.top/uatis'>Daniel Soares</a></h6>
      </div>
    </div>
  );
};

export default UatisChecker;
