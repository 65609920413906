import countryData from './data/countrycodes-detailed.json';

const CountryList = ({ onCountryChange }) => {
  const countries = countryData;

  const handleSelectChange = (event) => {
    const selectedDialCode = event.target.value;
    onCountryChange(selectedDialCode);
  };

  return (
    <select onChange={handleSelectChange}>
      <option value="">Select a country</option>
      {countries.map((country, index) => (
        <option key={index} value={country.dial_code}>
          {country.name} ({country.dial_code})
        </option>
      ))}
    </select>
  );
};

export default CountryList;

